.opinions {
  position: relative;
  width: 80%;
  margin: 0 auto;
  &-wrapper {
    padding: 5rem 0;
    > h2 {
      width: 80%;
      margin: 0 auto;
      padding-bottom: 5rem;
      color: #4c5636;
      font-size: 3rem;
    }
  }

  &-single {
    height: 15rem;
    width: 100%;
    padding: 1rem 5%;
    margin-right: 10px;
    border-radius: 0.7rem;
    background-color: #e8e4d9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      color: #b87239;
      font-weight: bold;
    }
  }
}

@media only screen and (min-width: 32rem) {
  //używamy important zeby nadpisać wartości domyślne komponentu Carousel
  .BrainhubCarousel {
    &__arrows {
      background-color: #b87239 !important;
      &:disabled {
        background-color: rgba(17, 19, 30, 0.2) !important;
      }
    }
    &__arrowRight {
      position: absolute !important;
      top: 20rem !important;
      left: calc(0% + 50px) !important;
    }
    &__arrowLeft {
      position: absolute !important;
      top: 20rem !important;
      left: 0 !important;
    }
  }
}

//small device 16px * 32rem = 512px
@media only screen and (max-width: 32rem) {
  .opinions {
    width: 90%;
    margin-bottom: 6rem;
    &-wrapper {
      padding: 0;
      > h2 {
        width: 90%;
        padding: 2rem 0;
        text-align: center;
      }
    }
  }
  //używamy important zeby nadpisać wartości domyślne komponentu Carousel
  .BrainhubCarousel {
    &__track {
      overflow: unset !important;
    }
    &__arrows {
      background-color: #b87239 !important;
      &:disabled {
        background-color: rgba(17, 19, 30, 0.2) !important;
      }
    }
    &__arrowRight {
      position: absolute !important;
      top: 20rem !important;
      left: calc(33% + 60px) !important;
    }
    &__arrowLeft {
      position: absolute !important;
      top: 20rem !important;
      left: 33% !important;
    }
  }
}
