.shop-offer {
  &-photo {
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      min-height: 400px;
    }
    h2 {
      position: absolute;
      top: 0;
      left: 14%;
      color: #8f512f;
      font-size: 4rem;
    }
  }
  &-article {
    padding: 4rem;
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 1.5rem;
    article {
      padding-top: 2rem;
    }
    h3 {
      color: #4c5636;
      font-size: 3rem;
      margin: 0;
    }
  }
}

//@extend device 16px * 32rem = 512px
@media only screen and (max-width: 32rem) {
  .shop-offer {
    &-photo {
      height: 20rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        min-height: unset;
      }
      h2 {
        left: 50%;
        transform: translate(-50%, -70%);
        font-size: 3rem;
      }
    }
    &-article {
      padding: 0;
      width: 90%;
      text-align: center;
      margin-top: 2rem;
      article {
        padding-top: 2rem;
      }
    }
  }
}
