.quantity-filter-sites {
  display: flex;
  flex-wrap: wrap;
  &-button {
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: transparent;
    border: 1px solid #b87239;
    color: #b87239;
    border-radius: 4px;
    width: 25px;
    height: 25px;
    padding: 0;
    text-align: center;
  }
  &-button:hover {
    background-color: #b87239;
    color: white;
  }
}
