.footer {
  background-color: #8f512f;
  padding: 3rem 10%;
  &-up {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 1fr;
    column-gap: 2%;
    align-items: start;
    &-logo-story {
      display: grid;
      article {
        margin-top: 40px;
        color: #e8e4d9;
        opacity: 0.5;
        width: 90%;
        font-weight: 700;
        font-size: 1.2rem;
      }
    }
    &-offer,
    &-contact,
    &-shop {
      display: grid;
      a {
        color: #e8e4d9;
        text-decoration: none;
        margin: 1rem 0;
      }
      p {
        font-weight: 700;
      }
      h5 {
        color: #e8e4d9;
        opacity: 0.5;
        font-size: 1.1rem;
      }
      div {
        display: grid;
        div {
          display: flex;
          span:nth-child(1) {
            margin-right: 10px;
          }
        }
        span {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          :hover {
            color: #4c5636;
          }
        }
      }
    }
    &-shop {
      p {
        margin: 0;
      }
    }
  }

  &-down {
    border-top: 1px solid #e8e4d9;
    margin: 3rem 0;
    color: #e8e4d9;
    opacity: 0.5;
    display: flex;
    justify-content: space-between;
    a {
      padding-top: 2rem;
      text-decoration: none;
      color: #e8e4d9;
    }
  }
}

//small device 16px * 32rem = 512px
@media only screen and (max-width: 32rem) {
  .footer {
    padding: 2rem 5%;
    &-up {
      h5 {
        margin: 1rem 0;
      }
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "logo logo"
        "offer contact"
        "shop shop";
      column-gap: 2%;
      &-logo-story {
        grid-area: logo;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
        article {
          display: none;
        }
      }
      &-offer {
        grid-area: offer;
        a {
          padding: 1rem 0;
        }
      }
      &-contact {
        grid-area: contact;
        a {
          padding: 1rem 0;
        }
      }
      &-shop {
        grid-area: shop;
        div {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
