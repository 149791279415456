.wrapper {
  background-color: #e8e4d9;
  > h2 {
    width: 80%;
    margin: 0 auto;
    font-size: 3rem;
    padding: 3rem 0;
    color: #4c5636;
  }
}

.offer {
  padding: 5rem 0;
  width: 80%;
  margin: 0 auto;
  display: grid;
  row-gap: 10rem;
  &-category {
    display: flex;
    justify-content: center;
    &-video {
      &-0 {
        order: -1;
      }
      &-2 {
        order: -1;
      }
      &-4 {
        order: -1;
      }
    }
    video {
      object-fit: cover;
      width: 50%;
      border-radius: 12px;
    }

    &-other-0,
    &-other-2,
    &-other-4 {
      width: 50%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 5%;
      h2 {
        font-size: 2rem;
      }

      article {
        margin-bottom: 2rem;
      }

      span {
        padding: 1rem 5%;
        border-radius: 0.5rem;
        background-color: #b87239;
        color: white;
        text-align: center;
        border-radius: 0.5rem;
        border: 1px solid transparent;
        &:hover {
          background-color: transparent;
          color: #b87239;
          border: 1px solid #b87239;
        }
      }
    }
    &-other-1,
    &-other-3,
    &-other-5 {
      width: 50%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-right: 5%;
      h2 {
        font-size: 2rem;
      }
      article {
        margin-bottom: 2rem;
      }
      span {
        padding: 1rem 5%;
        border-radius: 0.5rem;
        background-color: #b87239;
        color: white;
        text-align: center;
        border-radius: 0.5rem;
        border: 1px solid transparent;
        &:hover {
          background-color: transparent;
          color: #b87239;
          border: 1px solid #b87239;
        }
      }
    }
  }
}

//small device 16px * 32rem = 512px
@media only screen and (max-width: 32rem) {
  .wrapper {
    > h2 {
      width: 90%;
      padding: 2rem 0;
      text-align: center;
    }
  }
  .offer {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 90%;
    row-gap: 1rem;
    &-category {
      display: grid;
      &-other-0,
      &-other-1,
      &-other-2,
      &-other-3,
      &-other-4 {
        order: 2;
        padding: 0;
        width: 100%;
        h2 {
          font-size: 3rem;
          width: 100%;
          text-align: center;
        }
        article {
          width: 100%;
          text-align: center;
          font-size: 1.4rem;
        }
        span {
          margin: 1rem auto;
          width: 30%;
        }
      }
      video {
        object-fit: cover;
        width: 100%;
        height: 15rem;
        order: 1;
      }
    }
  }
}
