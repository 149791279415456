.form {
  display: flex;
  flex-wrap: wrap;
  row-gap: 3rem;
  column-gap: 1rem;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  &-wrapper {
    background-color: #e8e4d9;
    padding: 5rem 0;
    h2 {
      width: 80%;
      margin: 0 auto;
      padding-bottom: 2rem;
      color: #4c5636;
      font-size: 3rem;
    }
  }
  &-photo {
    width: 30%;
    flex-grow: 1;
    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: #b87239;
      height: 2rem;
      padding: 0.5rem 1rem;
      font-weight: bold;
      border-radius: 8px;
      border: 1px solid transparent;
      text-decoration: none;
      color: white;
      &:hover {
        background-color: transparent;
        color: #b87239;
        border: 1px solid #b87239;
      }
    }
    img {
      width: 100%;
      height: auto;
      min-height: 350px;
      object-fit: cover;
      border-radius: 0.75rem;
    }
    p {
      margin: 1rem 0;
    }
  }

  &-inputs {
    flex-basis: 65%;
    flex-grow: 1;
    display: grid;
    column-gap: 1rem;
    row-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "fname surname"
      "email phone "
      "subject subject"
      "message message";
    input {
      background-color: transparent;
      border: 2px solid #b87239;
      box-shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.1);
      border-radius: 5px;
      color: #b87239;
      padding-left: 1rem;
      font-size: 1rem;
      &::placeholder {
        color: #b87239;
        opacity: 0.7;
      }
    }
    textarea {
      resize: none;
      background-color: transparent;
      border: 2px solid #b87239;
      box-shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.1);
      border-radius: 5px;
      color: #b87239;
      padding-left: 1rem;
      padding-top: 0.6rem;
      font-size: 1rem;
      &::placeholder {
        color: #b87239;
        opacity: 0.7;
      }
      height: 10rem;
    }
    input[type="submit"] {
      -webkit-appearance: none;
      position: relative;
      top: 1rem;
      text-align: center;
      padding: 0;
      background-color: #b87239;
      max-width: 100px;
      height: 3rem;
      color: white;
      font-weight: bold;
      border: 1px solid transparent;
      border-radius: 8px;
      &:hover {
        background-color: transparent;
        color: #b87239;
        border: 1px solid #b87239;
      }
    }
  }
}

.name {
  grid-area: fname;
}

.surname {
  grid-area: surname;
}

.email {
  grid-area: email;
}

.message {
  grid-area: message;
}

.subject {
  grid-area: subject;
}
.phone {
  grid-area: phone;
}

//small device 16px * 32rem = 512px
@media only screen and (max-width: 32rem) {
  .form {
    column-gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-areas:
      "form-inputs"
      "form-send"
      "form-contact";
    width: 90%;
    &-wrapper {
      margin-top: 3rem;
      padding-top: 0;
      padding-bottom: 2rem;
      h2 {
        width: 90%;
        margin: 0 auto;
        padding: 2rem 0;
        text-align: center;
      }
    }
    &-contact {
      text-align: center;
    }
    &-photo {
      display: none;
    }
    &-send {
      display: flex;
      margin: 0 auto;
      width: 30%;
      span {
        width: 100%;
        grid-area: form-send;
        margin-top: 2rem;
      }
    }
    &-inputs {
      display: grid;
      column-gap: 0;
      row-gap: 1rem;
      grid-template-columns: 1fr;
      grid-template-areas:
        "fname"
        "surname"
        "email"
        "phone"
        "subject"
        "message";
      textarea {
        height: 15rem;
      }
      input[type="submit"] {
        position: relative;
        top: 0.5rem;
      }
    }
  }
}
