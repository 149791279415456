.filters-down {
  p {
    font-weight: bold;
  }
}
.filters {
  display: grid;
  row-gap: 2rem;
}
.filters-up {
  display: grid;
  justify-self: flex-start;
  h4 {
    margin: 0;
    letter-spacing: 0.11em;
  }
  span {
    border-radius: 4px;
    background-color: #b87239;
    color: white;
    font-weight: bold;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border: 1px solid transparent;
  }
  span:hover {
    background-color: transparent;
    color: #b87239;
    border: 1px solid #b87239;
  }
}
