.loc {
  &-wrapper {
    padding: 5rem 0;
    > h2 {
      width: 80%;
      margin: 0 auto;
      padding-bottom: 5rem;
      color: #4c5636;
      font-size: 3rem;
    }
  }
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  width: 80%;
  margin: 0 auto;
  &-map {
    width: 60%;
    min-height: 500px;
    flex-grow: 1;
    iframe {
      border-radius: 1.5rem;
    }
  }
  &-details {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    row-gap: 1rem;
    &-single {
      background-color: #e8e4d9;
      padding: 1rem 5%;
      border-radius: 0.75rem;
      cursor: unset;
      height: 100%;
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          width: 45%;
          white-space: pre-line;
        }
        h5 {
          font-size: 1rem;
        }
      }
    }
  }
}

//small device 16px * 32rem = 512px
@media only screen and (max-width: 32rem) {
  .loc {
    &-wrapper {
      padding: 0;
      > h2 {
        width: 90%;
        padding: 0;
        text-align: center;
        padding: 2rem 0;
      }
    }
    &-map {
      padding: 1rem 0;
      height: 25rem;
    }
    grid-template-columns: 1fr;
    width: 90%;
    &-details {
      display: none;
    }
  }
}
