@import url("https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&family=Tenor+Sans&display=swap");

//small device 16px * 32rem = 512px
@media only screen and (max-width: 32rem) {
  body {
    max-width: unset;
  }
}

body {
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  max-width: 1920px;
}

h1,
h2,
h3 {
  font-weight: 400;
  font-family: "Tenor Sans", sans-serif;
}

h4 {
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
}

li,
span,
h5,
a,
select,
button {
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
}

article,
p,
input,
textarea {
  font-weight: 400;
  font-family: "Quicksand", sans-serif;
}

a,
li,
svg,
span,
select,
button {
  cursor: pointer;
}
