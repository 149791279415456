.shelf-container {
  display: grid;
  width: 80%;
  margin: 0 auto;
  padding: 4rem 0;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 8rem auto;
  grid-template-areas:
    "filters headers headers headers headers"
    "filters products products products products"
    ". products products products products"
    ". navigation navigation navigation navigation";
  &-wrapper {
    background-color: #e8e4d9;
    margin-bottom: 5rem;
  }
  &-headers {
    grid-area: headers;
    display: flex;
    margin-left: auto;
    margin-right: 0;
    align-items: center;
    gap: 2rem;
    padding: 2rem 1rem;
  }
  &-filters {
    grid-area: filters;
  }

  &-products {
    grid-area: products;
    display: grid;
    column-gap: 1rem;
    row-gap: 2rem;
    padding: 0 2%;
    grid-template-columns: repeat(3, 1fr);
  }
  &-navigation {
    margin-top: 2rem;
    grid-area: navigation;
    justify-self: center;
  }
}

.shelf-item {
  &-wrapper {
    display: grid;
    height: 100%;
    grid-template-rows: minmax(5rem, 1fr) 5rem auto;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.75rem;
  }
  p {
    text-align: center;
  }
  span {
    display: inline-flex;
    justify-content: center;
    background-color: #b87239;
    color: white;
    border-radius: 8px;
    width: 30%;
    justify-self: center;
    text-align: center;
    padding: 1rem;
    border: 1px solid transparent;
  }
  span:hover {
    background-color: transparent;
    color: #b87239;
    border: 1px solid #b87239;
  }
}

@media only screen and (max-width: 45rem) {
  .shelf-container {
    &-products {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

//@extend device 16px * 32rem = 512px
@media only screen and (max-width: 32rem) {
  .shelf-container {
    display: grid;
    width: 90%;
    margin: 0 auto;
    padding: 2rem 0;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "filters filters"
      "headers headers"
      "products products"
      "navigation navigation";
    &-wrapper {
      background-color: #e8e4d9;
      margin: 2rem 0;
    }
    &-headers {
      grid-area: headers;
      display: grid;
      row-gap: 1.5rem;
      grid-template-columns: 1fr;
      justify-self: center;
      align-items: center;
      margin-left: unset;
      margin-right: unset;
    }
    &-filters {
      grid-area: filters;
      display: grid;
      grid-template-columns: 1fr;
      justify-self: center;
      align-items: center;
    }

    &-products {
      grid-area: products;
      display: grid;
      column-gap: 1rem;
      row-gap: 2rem;
      grid-template-columns: repeat(2, 1fr);
    }
    &-navigation {
      margin-top: 2rem;
      grid-area: navigation;
      justify-self: center;
    }
  }

  .shelf-item {
    &-wrapper {
      display: grid;
      height: 100%;
    }
    img {
      width: 100%;
      border-radius: 0.75rem;
    }
    p {
      text-align: center;
    }
    span {
      display: inline-flex;
      justify-content: center;
    }
    span:hover {
      background-color: transparent;
      color: #b87239;
      border: 1px solid #b87239;
    }
  }
}
