.sweet-buffet {
  position: relative;
  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    min-height: 450px;
  }
  h1 {
    position: absolute;
    top: 0;
    left: 10%;
    font-size: 4rem;
    z-index: 1;
    color: #e8e4d9;
  }

  &-social-media {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
    position: absolute;
    bottom: 5%;
    right: 5%;
    span {
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      :hover {
        color: #8f512f;
      }
    }
  }
}

//small device 16px * 32rem = 512px
@media only screen and (max-width: 32rem) {
  .sweet-buffet {
    height: 88vh;
    h1 {
      font-size: 3rem;
      width: 90%;
      left: 5%;
      text-align: center;
    }
    &-social-media {
      left: 50%;
      transform: translate(-50%, 0);
      right: unset;
    }
  }
}
