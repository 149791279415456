.main {
  display: flex;
  margin: 10px auto;
}

/* Create a custom checkbox */

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 1px solid #b87239;
  border-radius: 2px;
}

/* On mouse-over*/
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked*/
.container input:checked ~ .checkmark {
  background-color: #b87239;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.filter-checkbox-text {
  color: #c4c4c4;
  font-weight: bold;
  font-size: 0.8rem;
  margin-left: 4px;
  align-self: center;
}

.category-name {
  font-size: 1rem;
  line-height: 25px;
  font-weight: normal !important;
  margin: 0;
}
