.quantity-filter {
  display: flex;
  gap: 20px;
  p {
    margin: 0;
  }
  select {
    background-color: transparent;
    border-color: #b87239;
    color: #b87239;
    border-radius: 4px;
  }
}
