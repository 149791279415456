//small device 16px * 32rem = 512px
@media only screen and (max-width: 45rem) {
  .navbar-mobile {
    &-header {
      display: flex;
      align-items: center;
      justify-content: center;
      &-logo {
        width: 25%;
        height: auto;
        cursor: pointer;
      }
      &-hamburger {
        position: absolute;
        right: 5%;
      }
    }
    position: sticky;
    transition: top 0.3s;
    top: 0;
    width: 100%;
    margin: 0 auto;
    padding: 3vh 0;
    z-index: 3;
    background-color: white;
    &-links {
      position: absolute;
      display: grid;
      padding: 2rem 0;
      margin: 0;
      row-gap: 2rem;
      background-color: white;
      width: 100%;
      li {
        list-style-type: none;
        font-size: 1.4rem;
        text-align: center;
        color: #8f512f;
        &:hover {
          color: #4c5636;
          text-decoration: underline;
          text-underline-offset: 5px;
        }
      }
    }
  }
  .navbar-desktop {
    display: none;
  }
}

@media only screen and (min-width: 45rem) {
  .navbar-desktop {
    position: sticky;
    transition: top 0.3s;
    top: 0;
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0;
    z-index: 3;
    background-color: white;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1rem;
      width: 6%;
      min-width: 90px;
      height: auto;
      cursor: pointer;
    }
    &-links {
      margin: 0 auto;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4rem;
      width: 80%;
      li {
        list-style-type: none;
        font-size: 0.8rem;
        text-align: center;
        color: #8f512f;
        &:hover {
          color: #4c5636;
          text-decoration: underline;
          text-underline-offset: 5px;
        }
      }
      &-about,
      &-offer,
      &-location,
      &-coop,
      &-contact {
        &.active {
          color: #4c5636;
          text-decoration: underline;
          text-underline-offset: 5px;
        }

      }
    }
  }
  .navbar-mobile {
    display: none;
  }
  .hovered{
    color: #4c5636 !important;
    text-decoration: underline !important;;
    text-underline-offset: 5px !important;;
  }
}
