.co-op {
  &-photo {
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      min-height: 400px;
    }
    h2 {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #8f512f;
      font-size: 4rem;
    }
  }
  &-article {
    padding: 4rem;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 1.5rem;
    article {
      padding-top: 2rem;
    }
    h3 {
      color: #4c5636;
      font-size: 3rem;
      margin: 0;
    }
  }
  &-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    width: 80%;
    margin: 0 auto;
    :nth-child(1) {
      padding-right: 10px;
      padding-bottom: 10px;
      height: 500px;
      width: auto;
      object-fit: cover;
      flex-grow: 1;
    }
    :nth-child(2) {
      width: auto;
      height: 500px;
      object-fit: cover;
      flex-grow: 1;
    }
  }
}

//small device 16px * 32rem = 512px
@media only screen and (max-width: 32rem) {
  .co-op {
    &-photo {
      height: 20rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        min-height: unset;
      }
      h2 {
        left: 50%;
        transform: translate(-50%, -70%);
        font-size: 3rem;
      }
    }
    &-article {
      padding: 0;
      width: 90%;
      text-align: center;
      margin-top: 2rem;
      article {
        padding: 2rem 0;
      }
    }
    &-images {
      :nth-child(1) {
        height: 220px;
        padding-right: 0;
      }
      :nth-child(2) {
        height: 220px;
      }
    }
  }
}
