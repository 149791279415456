.contact-us {
  width: 80%;
  margin: 5rem auto;
  height: 8rem;
  background-color: #e8e4d9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  h4 {
    margin-left: 5%;
    margin-right: 5%;
    font-size: 2rem;
    text-align: center;
  }
  span {
    display: inline-flex;
    background-color: #b87239;
    margin-right: 5%;
    color: white;
    padding: 1rem 2rem;
    border-radius: 8px;
    min-width: 110px;
  }
}

//small device 16px * 32rem = 512px
@media only screen and (max-width: 32rem) {
  .contact-us {
    width: 90%;
    flex-direction: column;
    margin: 2rem auto;
    padding-bottom: 2rem;
    h4 {
      margin-left: 0;
      font-size: 1rem;
    }
    span {
      margin-right: 0;
      min-width: unset;
    }
  }
}
