.sweet-tradition {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: flex-start;
  grid-template-rows: auto;
  grid-template-areas:
    "img2 h2 h2"
    "img2 article article"
    "img2 button button"
    "img2 img1 img1";
  width: 80%;
  margin: 0 auto;
  column-gap: 5%;
  &-img1 {
    grid-area: img1;
    width: 100%;
    height: auto;
    object-fit: contain;
    padding: 3rem 0;
  }
  &-img2 {
    grid-area: img2;
    width: 100%;
    object-fit: contain;
    height: auto;
    align-self: center;
  }
  h2 {
    grid-area: h2;
    margin-left: 5%;
    font-size: 3rem;
    padding-top: 2rem;
    color: #4c5636;
  }

  article {
    grid-area: article;
    width: 80%;
    font-size: 1rem;
    margin-left: 5%;
    padding-bottom: 3rem;
  }

  span {
    grid-area: button;
    margin-left: 5%;
    background-color: #b87239;
    padding: 1rem 5%;
    text-align: center;
    border-radius: 0.5rem;
    color: white;
    border: 1px solid transparent;
    &:hover {
      background-color: transparent;
      color: #b87239;
      border: 1px solid #b87239;
    }
  }
}

//small device 16px * 32rem = 512px
@media only screen and (max-width: 45rem) {
  .sweet-tradition {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "h2 h2"
      "img1 img2"
      "article article"
      "button button";
    width: 90%;
    height: auto;
    &-img1 {
      width: 100%;
      height: 15rem;
      object-fit: cover;
      padding: 0;
      min-width: unset;
    }
    &-img2 {
      width: 100%;
      height: 15rem;
      object-fit: cover;
      min-width: unset;
    }
    h2 {
      text-align: center;
      margin: 0 auto;
      font-size: 3rem;
      padding: 2rem 0;
    }
    article {
      text-align: center;
      width: 100%;
      font-size: 1.3rem;
      margin: 0 auto;
      padding: 2rem 0;
    }
    span {
      display: flex;
      width: 20%;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2rem;
    }
  }
}
