.newsletter {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  justify-content: center;
  align-items: flex-end;
  background-color: #e8e4d9;
  width: 70%;
  margin: 4rem auto;
  padding: 3rem 5%;
  border-radius: 12px;
  &-text {
    width: 55%;
    margin: 0 auto;
    display: grid;
    h4 {
      font-size: 2rem;
    }
  }
  &-input {
    &-form {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 1rem;
      input:nth-child(1) {
        background-color: transparent;
        border: 2px solid #b87239;
        box-shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.1);
        border-radius: 5px;
        color: #b87239;
        padding: 1rem 4rem;
        font-size: 1rem;
        &::placeholder {
          color: #b87239;
          opacity: 0.7;
        }
      }
      input:nth-child(2) {
        -webkit-appearance: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #b87239;
        color: white;
        font-weight: bold;
        padding: 1rem 2rem;
        border-radius: 8px;
        border: 1px solid transparent;
        &:hover {
          background-color: transparent;
          color: #b87239;
          border: 1px solid #b87239;
        }
      }
    }
  }
}

//small device 16px * 32rem = 512px
@media only screen and (max-width: 32rem) {
  .newsletter {
    flex-direction: column;
    width: 90%;
    margin: 2rem auto;
    padding: 0;
    &-text {
      width: 90%;
      h4 {
        font-size: 2rem;
        text-align: center;
      }
    }

    &-input {
      margin: 0 auto;
      &-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        input:nth-child(1) {
          margin-top: 2rem;
          margin-bottom: 1rem;
          padding: 1rem 2rem;
        }
        input:nth-child(2) {
          margin-bottom: 2rem;
          padding: 0.5rem 2rem;
        }
      }
    }
  }
}
