.about-us {
  &-photo {
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      min-height: 400px;
    }
    h2 {
      position: absolute;
      top: 0;
      left: 14%;
      color: #8f512f;
      font-size: 4rem;
    }
  }
  &-article {
    padding: 4rem 0;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    article {
      padding-top: 2rem;
      font-size: 1.5rem;
    }
    h3 {
      color: #4c5636;
      font-size: 3rem;
      margin: 0;
    }
  }
}

.owners {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  :nth-child(1),
  :nth-child(3) {
    flex-basis: 24%;
    flex-grow: 1;
  }
  :nth-child(2) {
    flex-basis: 49%;
    min-width: 400px;
    flex-grow: 1;
  }
  figure {
    height: 30rem;
    margin: 0;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
}

.story {
  article {
    width: 80%;
    margin: 4rem auto;
    font-size: 1.5rem;
    text-align: center;
  }
}

.timeline {
  display: grid;
  &-wrapper {
    padding-top: 0.5rem;
    padding-bottom: 5rem;
    padding-left: 10%;
    padding-right: 10%;
    background-color: #e8e4d9;
    h3 {
      color: #4c5636;
      font-size: 3rem;
    }
  }
  &-year {
    display: flex;
    flex-wrap: wrap;
    color: #b87239;
    opacity: 0.3;

    &.active {
      opacity: 1;
    }
    &-text {
      article {
        padding: 1rem 0;
        font-size: 1.2rem;
      }
    }
    &-right {
      width: 60%;
    }
    &-left {
      display: flex;
      margin-right: 36px;
    }
    p {
      margin: 0;
      margin-right: 30px;
      font-weight: bold;
      font-size: 1.3rem;
      width: 40px;
    }
    h4 {
      font-size: 1.3rem;
      font-weight: bold;
    }
    img {
      align-self: center;
      width: 200px;
      height: 200px;
      margin: 1rem 0;
      border-radius: 12px;
      object-fit: cover;
    }
    &-line {
      position: relative;
      &-vertical {
        background-color: #b87239;
        width: 1px;
        height: 100%;
      }
      &-dot {
        position: absolute;
        top: 0;
        left: -1rem;
        padding: 1rem;
        border-radius: 50%;
        background-color: #b87239;
        opacity: 0.3;
        &.active {
          opacity: 1;
        }
      }
    }
  }
}

//small device 16px * 32rem = 512px
@media only screen and (max-width: 32rem) {
  .about-us {
    &-photo {
      height: 20rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        min-height: unset;
      }
      h2 {
        left: 50%;
        transform: translate(-50%, -70%);
        font-size: 3rem;
      }
    }
    &-article {
      padding: 0;
      width: 90%;
      text-align: center;
      article {
        padding: 2rem 0;
      }
      h3 {
        padding-top: 2rem;
      }
    }
  }
  .owners {
    width: 90%;
    :nth-child(2) {
      min-width: 270px;
    }
    figure {
      height: unset;
      margin: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 12px;
    }
  }

  .timeline {
    &-wrapper {
      padding-right: 5%;
      padding-left: 5%;
      h3 {
        text-align: center;
      }
    }
    &-year {
      flex-direction: column;
      &-right {
        width: 100%;
      }
      &-left {
        height: 5rem;
      }
      img {
        width: 150px;
        height: 150px;
      }
    }
  }
}
